import React from 'react';
import "../style/Loading.css"

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-screen z-100">
     <svg className="w-30 h-30 custom-spin" width="42" height="47" viewBox="0 0 42 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.93 46.944C18.562 46.944 16.962 44.704 16.962 41.824C16.962 40.224 17.538 37.792 18.69 34.528C19.842 31.2 20.418 28 20.418 24.672C17.602 26.4 15.49 28.192 13.89 29.984C10.69 33.568 7.554 37.088 4.226 37.088C2.178 37.088 0.514 35.488 0.514 33.568C0.514 31.456 2.242 29.344 6.018 28.32C7.874 27.808 9.986 27.232 12.354 26.784C14.658 26.336 17.218 25.312 19.906 23.776C17.154 22.24 14.53 21.216 12.098 20.704C7.298 19.68 3.458 18.848 1.794 16.928C0.962 15.968 0.514 14.944 0.514 13.856C0.514 11.936 2.05 10.464 4.29 10.464C7.682 10.464 11.65 14.944 14.466 18.144C15.874 19.744 17.858 21.344 20.418 22.88V22.048C20.418 19.488 20.226 17.376 19.714 15.84C18.69 12.768 17.09 7.968 17.09 5.728C17.09 2.784 18.754 0.607998 21.058 0.607998C23.298 0.607998 25.026 2.656 25.026 5.728C25.026 7.264 24.45 9.76 23.298 13.152C22.146 16.544 21.57 19.808 21.57 22.88C25.218 20.576 24.77 20.832 27.906 17.824C30.914 14.496 33.026 12.448 34.178 11.68C35.394 10.848 36.61 10.464 37.89 10.464C39.938 10.464 41.474 11.872 41.474 13.856C41.474 16.032 39.874 17.888 36.162 19.104C34.37 19.68 32.258 20.192 29.89 20.704C27.458 21.216 24.898 22.24 22.082 23.776C24.834 25.312 27.33 26.272 29.698 26.784C34.37 27.808 38.338 28.704 40.13 30.56C41.026 31.52 41.474 32.48 41.474 33.568C41.474 35.488 39.938 37.088 37.698 37.088C33.922 37.088 29.058 31.072 27.202 28.96C26.306 27.936 24.386 26.464 21.57 24.672V25.504C21.57 28.256 22.146 31.328 23.234 34.656C24.322 37.984 24.898 40.352 24.898 41.824C24.898 44.832 23.17 46.944 20.93 46.944Z" fill="black"/>
    </svg>
    </div>
  );
};

export default Loading;