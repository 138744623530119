import React from 'react';
import ChatHeader from '../components/ChatHeader';
import ChatMainSection from '../components/ChatMainSection';

function Chat() {
  return (
    <>
      <ChatHeader />
      <ChatMainSection />
    </>
  );
}

export default Chat;
